<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="minmax" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagMinMax"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ MIN/MAX Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('minMax')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Min/Max">
                        <Panel header="Minimum-Stellungen im Regelbetrieb">
                            <template #icons>
                                <i class="fi fi-rr-arrow-to-bottom bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="minValueLuv" ref="minValueLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungMinLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungMinLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="minValueLuv" class="mr-2">MIN-Stellung LUV</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="minValueLee" ref="minValueLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungMinLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungMinLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="minValueLee" class="mr-2">MIN-Stellung LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Maximum-Stellungen im Regelbetrieb" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-arrow-to-top bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="maxValueLuv" ref="maxValueLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungMaxLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungMaxLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="maxValueLuv" class="mr-2">MAX-Stellung LUV</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="maxValueLee" ref="maxValueLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungMaxLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungMaxLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="maxValueLee" class="mr-2">MAX-Stellung LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <!-- <div class="field">
                    <div class="grid justify-content-center">
                        <h5>{{ currentGrp.label }}</h5>
                    </div>
                </div> -->
                    <TabPanel header="Zeitbegrenzungen">
                        <Panel header="Zeitbegrenzung MIN-Stellung (übersteuert MIN Regelbetrieb)">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Wenn die MIN-Begrenzung aktiviert wird, öffnet die Lüftung unabhängig vom aktuellen Lüftungssollwert auf die eingestellten MIN-Zeitbegrenzungen, sofern das Zeitfenster zutrifft. Wird ein Zusatzprogramm wie z.B. <b>MIN-Spalt Heizungssollwert</b> aktiviert, arbeitet der MIN-Spalt nicht mehr nur nach Zeit, sondern zusätzlich mit dem aktuellen Heizungssollwert sowie Außentemperatur zusammen. Dies ermöglicht ein Öffnen der Lüftung, wenn der Abstand zur Heizung erreicht oder überschritten wird. `"></i>
                                <i class="fi fi-rr-time-quarter-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="field col-12 mb-3">
                                <div class="formgrid grid p-1">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagData('CFG.stellungMinZeit').value" /><span class="ml-2">MIN-Begrenzung aktivieren</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid" v-if="this.getDiagData('CFG.stellungMinZeit').value">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagData('CFG.stellungMinZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagData('CFG.stellungMinZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagData('CFG.stellungMinZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagData('CFG.stellungMinZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="minValueTimeStart" class="mr-2">MIN-Zeitbegrenzung von</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="minTimeValueLuv" ref="minValueTimeLuv" :min="0" :max="100"
                                                v-model="this.getDiagData('CFG.stellungMinZeitLuv').value" mode="decimal"
                                                :suffix="' ' + this.getDiagData('CFG.stellungMinZeitLuv').unit"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="minValueTimeLuv" class="mr-2">MIN-Zeitbegrenzung LUV</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagData('CFG.stellungMinZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagData('CFG.stellungMinZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagData('CFG.stellungMinZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagData('CFG.stellungMinZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd" class="mr-2">MIN-Zeitbegrenzung bis</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="p-float-label">
                                            <InputNumber id="mixTimeValueLee" ref="mixValueTimeLee" :min="0" :max="100"
                                                v-model="this.getDiagData('CFG.stellungMinZeitLee').value" mode="decimal"
                                                :suffix="' ' + this.getDiagData('CFG.stellungMinZeitLee').unit"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="minValueTimeLee" class="mr-2">MIN-Zeitbegrenzung LEE</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid" v-if="this.getDiagData('CFG.stellungMinZeit').value">
                                <div class="field col-12">
                                    <div class="card flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagData('CFG.stellungMinZeitHzgEnable').value" inputId="CFG.stellungMinZeitHzgEnable" :binary="true" />
                                            <label for="CFG.stellungMinZeitHzgEnable" class="ml-2"> {{ this.getDiagData('CFG.stellungMinZeitHzgEnable').label }} </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="formgrid grid" v-if="this.getDiagData('CFG.stellungMinZeitHzgEnable').value">
                                    <div class="field col-4">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputNumber id="CFG.stellungMinZeitHzgAt" ref="CFG.stellungMinZeitHzgAt" :min="0" :max="100"
                                                    v-model="this.getDiagData('CFG.stellungMinZeitHzgAt').value" autofocus @focus="focusElement"
                                                    :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                    class="p-inputtext-lg w-full" />
                                                    <label for="CFG.stellungMinZeitHzgAt" class="mr-2">{{ this.getDiagData('CFG.stellungMinZeitHzgAt').label }}</label>
                                                <span class="p-inputgroup-addon nice-font text-lg">{{ this.getDiagData('CFG.stellungMinZeitHzgAt').unit }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field col-4">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputNumber id="CFG.stellungMinZeitHzgDiff" ref="CFG.stellungMinZeitHzgDiff" :min="0" :max="100"
                                                    v-model="this.getDiagData('CFG.stellungMinZeitHzgDiff').value" autofocus @focus="focusElement"
                                                    :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                    class="p-inputtext-lg w-full" />
                                                    <label for="CFG.stellungMinZeitHzgDiff" class="mr-2">{{ this.getDiagData('CFG.stellungMinZeitHzgDiff').label }}</label>
                                                <span class="p-inputgroup-addon nice-font text-lg">{{ this.getDiagData('CFG.stellungMinZeitHzgDiff').unit }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field col-4" v-if="isMaster">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputNumber id="CFG.stellungMinZeitHzgHyst" ref="CFG.stellungMinZeitHzgHyst" :min="0" :max="100"
                                                    v-model="this.getDiagData('CFG.stellungMinZeitHzgHyst').value" autofocus @focus="focusElement"
                                                    :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                    class="p-inputtext-lg w-full" />
                                                    <label for="CFG.stellungMinZeitHzgHyst" class="mr-2">{{ this.getDiagData('CFG.stellungMinZeitHzgHyst').label }}</label>
                                                <span class="p-inputgroup-addon nice-font text-lg">{{ this.getDiagData('CFG.stellungMinZeitHzgHyst').unit }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Zeitbegrenzung MAX-Stellung (übersteuert MAX Regelbetrieb)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-time-quarter-to bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="field mb-3">
                                <div class="formgrid grid p-1">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagData('CFG.stellungMaxZeit').value" /><span class="ml-2">MAX-Begrenzung aktivieren</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagData('CFG.stellungMaxZeit').value">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagData('CFG.stellungMaxZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagData('CFG.stellungMaxZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagData('CFG.stellungMaxZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="maxTimeStart" ref="maxValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagData('CFG.stellungMaxZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="maxValueTimeStart" class="mr-2">MAX-Zeitbegrenzung von</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="maxTimeValueLuv" ref="maxValueTimeLuv" :min="0" :max="100"
                                                v-model="this.getDiagData('CFG.stellungMaxZeitLuv').value" mode="decimal"
                                                :suffix="' ' + this.getDiagData('CFG.stellungMaxZeitLuv').unit"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="maxValueTimeLuv" class="mr-2">MAX-Zeitbegrenzung LUV</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagData('CFG.stellungMaxZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagData('CFG.stellungMaxZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagData('CFG.stellungMaxZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="maxTimeEnd" ref="maxValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagData('CFG.stellungMaxZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="maxValueTimeEnd" class="mr-2">MAX-Zeitbegrenzung bis</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="p-float-label">
                                            <InputNumber id="maxTimeValueLee" ref="maxValueTimeLee" :min="0" :max="100"
                                                v-model="this.getDiagData('CFG.stellungMaxZeitLee').value" mode="decimal"
                                                :suffix="' ' + this.getDiagData('CFG.stellungMaxZeitLee').unit"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="maxValueTimeLee" class="mr-2">MAX-Zeitbegrenzung LEE</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Wind">
                        <Panel header="Wind-Werte für MAX-Windbegrenzungswert">
                            <template #icons>
                                <i class="fi fi-rr-wind bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="windLimitLuv" ref="windLimitLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungWindLuvAb').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungWindLuvAb').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windLimitLuv" class="mr-2">Windbegrenzung LUV ab</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="windLimitLee" ref="windLimitLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungWindLeeAb').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungWindLeeAb').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windLimitLee" class="mr-2">Windbegrenzung LEE ab</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="MAX-Windbegrenzung bei obrigen Windwerten" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-sort bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="windLimitValueLuv" ref="windLimitValueLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungWindLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungWindLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windLimitValueLuv" class="mr-2">MAX-Windbegrenzung LUV</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="windLimitValueLee" ref="windLimitValueLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungWindLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungWindLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windLimitValueLee" class="mr-2">MAX-Windbegrenzung LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Regen">
                        <Panel header="Regenbegrenzung">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Sobald die Wetterstation Niederschlag registriert, wird die Lüftung auf die hier eingestellten Werte begrenzt, vorausgesetzt die Lüftung muss laut Temperatursteuerung geöffnet sein.`"></i>
                                <i class="fi fi-rr-cloud-showers bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="rainLimitValueLuv" ref="rainLimitValueLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungRegenLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungRegenLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="rainLimitValueLuv" class="mr-2">MAX-Regenbegrenzung LUV</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="rainLimitValueLee" ref="rainLimitValueLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungRegenLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungRegenLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="rainLimitValueLee" class="mr-2">MAX-Regenbegrenzung LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Intelligente Regenbegrenzung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Zusätzlich zur obigen Regenbegrenzung ist es möglich, die Lüftung <b>AB</b> einer gewissen Art von Niederschlag andere Werte anfahren zu lassen. Dabei sollten die hier eingestellten Werte <b>kleiner</b> sein, als jene der normalen Regenbegrenzung (oben).`"></i>
                                <i class="fi fi-rr-cloud-showers bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagData('CFG.stellungRegenCustom').value" :options="getSynoptoCode" optionLabel="label" optionValue="value" placeholder="Niederschlagsart wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="stellungRegenLuvMax" ref="stellungRegenLuvMax" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungRegenLuvMax').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungRegenLuvMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungRegenLuvMax" class="mr-2">{{ this.getDiagData('CFG.stellungRegenLuvMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungRegenLeeMax" ref="stellungRegenLeeMax" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungRegenLeeMax').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungRegenLeeMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungRegenLeeMax" class="mr-2">{{ this.getDiagData('CFG.stellungRegenLeeMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="isMaster">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="regenCustomDelayOn" ref="regenCustomDelayOn" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.regenCustomDelayOn').value"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="regenCustomDelayOn" class="mr-2">{{ this.getDiagData('CFG.regenCustomDelayOn').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="regenCustomDelayOff" ref="regenCustomDelayOff" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.regenCustomDelayOff').value"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="regenCustomDelayOff" class="mr-2">{{ this.getDiagData('CFG.regenCustomDelayOff').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="MAX-Stellung nach Regen (Regenverzögerung)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cloud-disabled bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                    Die <b>MAX-Verzögerungsbegrenzung</b> zwingt die Lüftung nach Regen in die eingestellte Begrenzung, sofern ein Bedarf besteht. Wird die Zeit auf 00:00:00 gestellt, ist die Funktion deaktiviert.
                                </InlineMessage>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="rainValueCooldownTime" ref="rainValueCooldownTime" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungRegenZeit').value"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="rainValueCooldownTime" class="mr-2">Regenverzögerung</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="rainValueCooldownLuvLee" ref="rainValueCooldownLuvLee" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungRegenZeitMax').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungRegenZeitMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="rainValueCooldownLuvLee" class="mr-2">MAX-Verzögerungsbegrenzung</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sturm">
                        <Panel header="MAX-Stellung bei Sturm (Vorsicht!)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-wind-warning bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                    Wenn hier ein Wert größer als 0% eingetragen wird, schließt die Lüftung <span class="fg-lightCrimson"><b><u>nicht</u></b></span> komplett bei Sturm!
                                </InlineMessage>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="stormLimitValueLuv" ref="stormLimitValueLuv" :min="0"
                                            :max="100" v-model="this.getDiagData('CFG.stellungSturmLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungSturmLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stormLimitValueLuv" class="mr-2">MAX-Sturmbegrenzung LUV</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="stormLimitValueLee" ref="stormLimitValueLee" :min="0"
                                            :max="100" v-model="this.getDiagData('CFG.stellungSturmLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungSturmLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stormLimitValueLee" class="mr-2">MAX-Sturmbegrenzung LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Frost">
                        <Panel header="Lüftungssperren (Vereisungsschutz) ab Außentemperaturen">
                            <template #icons>
                                <i class="fi fi-rr-temperature-frigid bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="frostLimitValueDay" ref="frostLimitValueDay" :min="-50"
                                            :max="100" v-model="this.getDiagData('CFG.sperreFrostTag').value"
                                            :suffix="' ' + this.getDiagData('CFG.sperreFrostTag').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="frostLimitValueDay" class="mr-2">Sperre (Tag) ab A-Temp</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="frostLimitValueNight" ref="frostLimitValueNight" :min="-50"
                                            :max="100" v-model="this.getDiagData('CFG.sperreFrostNacht').value"
                                            :suffix="' ' + this.getDiagData('CFG.sperreFrostNacht').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="frostLimitValueNight" class="mr-2">Sperre (Nacht) ab A-Temp</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Entfeuchtung">
                        <Panel header="Einstellungen bei Entfeuchtung durch Lüftung">
                            <template #icons>
                                <i class="fi fi-rr-temperature-frigid bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="stellungFeuchteMin" ref="stellungFeuchteMin" :min="-50"
                                            :max="100" v-model="this.getDiagData('CFG.stellungFeuchteMin').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungFeuchteMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungFeuchteMin" class="mr-2">{{ this.getDiagData('CFG.stellungFeuchteMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungFeuchteMax" ref="stellungFeuchteMax" :min="-50"
                                            :max="100" v-model="this.getDiagData('CFG.stellungFeuchteMax').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungFeuchteMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungFeuchteMax" class="mr-2">{{ this.getDiagData('CFG.stellungFeuchteMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="stellungFeuchteSperreAt" ref="stellungFeuchteSperreAt" :min="-50"
                                            :max="100" v-model="this.getDiagData('CFG.stellungFeuchteSperreAt').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungFeuchteSperreAt').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungFeuchteSperreAt" class="mr-2">{{ this.getDiagData('CFG.stellungFeuchteSperreAt').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMinMax" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('minMax')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel ref="controller" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagController"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '600px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Regler Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('controller')" >
                <TabView>
                    <TabPanel header="Abstände">
                        <Panel header="Abstände zum Lüftungs-Sollwert">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="abstandValue" ref="abstandValue" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.abstand').value"
                                            :suffix="' ' + this.getDiagData('CFG.abstand').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="abstandValue" class="mr-2">Abstand LEE</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="abstandValueLuv" ref="abstandValueLuv" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.luvAbstand').value"
                                            :suffix="' ' + this.getDiagData('CFG.luvAbstand').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="abstandValueLuv" class="mr-2">Abstand LUV zu LEE</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="P-Regelbereich (Solltemperatur+P-Bereich=100% offen)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-square-root bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="controllerPLee" ref="controllerPLee" :min="0.1" :max="100"
                                            v-model="this.getDiagData('CFG.pAnteilLee').value"
                                            :suffix="' ' + this.getDiagData('CFG.pAnteilLee').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="controllerPLee" class="mr-2">P-Bereich LEE</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="controllerPLuv" ref="controllerPLuv" :min="0.1" :max="100"
                                            v-model="this.getDiagData('CFG.pAnteilLuv').value"
                                            :suffix="' ' + this.getDiagData('CFG.pAnteilLuv').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="controllerPLuv" class="mr-2">P-Bereich LUV</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Intern" v-if="isMaster">
                        <Panel header="Außentemperatur-Einfluss" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-temperature-hot bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="controllerATEinfluss" ref="controllerATEinfluss" :min="0"
                                            :max="100" v-model="this.getDiagData('CFG.atEinfluss').value"
                                            :suffix="' ' + this.getDiagData('CFG.atEinfluss').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="controllerATEinfluss" class="mr-2">Außtemperatur-Einfluss</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="controllerI" ref="controllerI" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.iAnteil').value" mode="decimal"
                                            locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="controllerI" class="mr-2">I-Bereich</label>
                                    </span>
                                </div>
                                <div class="field col-6" v-if="isMaster">
                                    <span class="p-float-label">
                                        <InputNumber id="CFG.controlAtHyst" ref="CFG.controlAtHyst" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.controlAtHyst').value" mode="decimal"
                                            :suffix="' ' + this.getDiagData('CFG.controlAtHyst').unit"
                                            locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="CFG.controlAtHyst" class="mr-2">{{ this.getDiagData('CFG.controlAtHyst').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Schaltschwellen für Lüftungsbewegungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-tachometer-average bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="switchMin" ref="switchMin" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.schaltschwelleMin').value"
                                            :suffix="' ' + this.getDiagData('CFG.schaltschwelleMin').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="switchMin" class="mr-2">Schaltschwelle MIN</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="switchMax" ref="switchMax" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.schaltschwelleMax').value"
                                            :suffix="' ' + this.getDiagData('CFG.schaltschwelleMax').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="switchMax" class="mr-2">Schaltschwelle MAX</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="switchMin" ref="switchMin" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.schaltschwelle0').value"
                                            :suffix="' ' + this.getDiagData('CFG.schaltschwelle0').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="switchMin" class="mr-2">0% Schaltpunkt</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="switchMax" ref="switchMax" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.schaltschwelle100').value"
                                            :suffix="' ' + this.getDiagData('CFG.schaltschwelle100').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="switchMax" class="mr-2">100% Schaltpunkt</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungDeadZone" ref="stellungDeadZone" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungDeadZone').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungDeadZone').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="stellungDeadZone" class="mr-2">{{ this.getDiagData('CFG.stellungDeadZone').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungWindLuvLeeAb" ref="stellungWindLuvLeeAb" :min="0" :max="100"
                                            v-model="this.getDiagData('CFG.stellungWindLuvLeeAb').value"
                                            :suffix="' ' + this.getDiagData('CFG.stellungWindLuvLeeAb').unit" :minFractionDigits="1"
                                            mode="decimal" locale="de-DE" :useGrouping="false" @focus="focusElement"
                                            class="p-inputtext-lg w-full" />
                                        <label for="stellungWindLuvLeeAb" class="mr-2">{{ this.getDiagData('CFG.stellungWindLuvLeeAb').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Verzögerungen und Berechnungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-tachometer-average bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="stellungWindCheckDown" ref="stellungWindCheckDown" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungWindCheckDown').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungWindCheckDown">{{ this.getDiagData('CFG.stellungWindCheckDown').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="stellungWindCheckUp" ref="stellungWindCheckUp" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungWindCheckUp').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungWindCheckUp">{{ this.getDiagData('CFG.stellungWindCheckUp').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="stellungDelayWind" ref="stellungDelayWind" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungDelayWind').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungDelayWind">{{ this.getDiagData('CFG.stellungDelayWind').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="stellungDelayWindDirection" ref="stellungDelayWindDirection" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungDelayWindDirection').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungDelayWindDirection">{{ this.getDiagData('CFG.stellungDelayWindDirection').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="stellungDelayTemp" ref="stellungDelayTemp" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.stellungDelayTemp').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungDelayTemp">{{ this.getDiagData('CFG.stellungDelayTemp').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Laufzeit/Typ" v-if="isMaster">
                        <Panel header="Laufzeiten für beide Seiten" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-stopwatch bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="laufzeit1" ref="laufzeit1" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.laufzeit1').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="laufzeit1">{{ this.getDiagData('CFG.laufzeit1').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputMask id="laufzeit2" ref="laufzeit2" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.laufzeit2').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="laufzeit2">{{ this.getDiagData('CFG.laufzeit2').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Laufzeitsynchronisierung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-stopwatch bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagData('CFG.enableSync').value" /><span class="ml-2">{{ this.getDiagData('CFG.enableSync').label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagData('CFG.enableSync').value === true">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputMask id="intervalSync" ref="intervalSync" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.intervalSync').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="intervalSync">{{ this.getDiagData('CFG.intervalSync').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputMask id="runtimeAddSync" ref="runtimeAddSync" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagData('CFG.runtimeAddSync').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="runtimeAddSync">{{ this.getDiagData('CFG.runtimeAddSync').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <InlineMessage severity="info" class="mt-2 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }" v-if="this.getDiagData('CFG.enableSync').value === true">
                                Zeit bis zur nächsten Synchronisierung: <b>{{ this.getModuleValue('OUT_SyncInterval').value }}</b>
                            </InlineMessage>
                        </Panel>
                        <Panel header="Reglerart (Laufzeit, Stellungsgeber,...) und Ausrichtung N/S,O/W.." class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagData('CFG.reglerart').value" :options="getVentsControlType" optionLabel="label" optionValue="value" placeholder="Steuerungsart wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagData('CFG.ausrichtung').value" :options="getVentsOrientation" optionLabel="label" optionValue="value" placeholder="Ausrichtung wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagData('CFG.enableSide1').value" /><span class="ml-2">{{ this.getDiagData('CFG.enableSide1').label }}</span>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagData('CFG.enableSide2').value" /><span class="ml-2">{{ this.getDiagData('CFG.enableSide2').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleController" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('controller')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.key2">
                <span>🔴 {{ element.value }}</span>
            </div>
        </OverlayPanel>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="alarmStatus > 0" class="alarm-overlay">
            <div class="alarmticker">
                <span>{{ alarmTicker }}</span>
            </div>
        </div>  
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div :key="alarmStatus" v-show="true" class="hidden md:inline-flex social-icon" v-badge="alarmQueue" @click="toogleAlarmOverlay">
                        <i :class="`${icon} fs-xxlarge icon-md`"></i>
                    </div>
                    <div class="flex mt-1 ml-2 align-items-center">
                        <widget-mini-progress :label="orientationStr1" :value="getModuleValue('OUT_Stellung_1_Aktuell')" v-if="this.getModuleValue('CFG.enableSide1').value === true"></widget-mini-progress>
                        <widget-mini-progress :label="orientationStr2" :value="getModuleValue('OUT_Stellung_2_Aktuell')" v-if="this.getModuleValue('CFG.enableSide2').value === true"></widget-mini-progress>
                        <widget-mini-binary-pulse label="SYNC" :value="this.getModuleValue('OUT_Sync')" :icon="this.getModuleValue('OUT_Sync').icon" v-if="this.getModuleValue('CFG.enableSync').value === true"></widget-mini-binary-pulse>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="getModuleValue('OUT_Stellung_Max')" :moduleData="this.moduleData" @showChart="showChart('value1')" />
                    <!-- <widget-header :value="getModuleValue('IN_RT')" :moduleData="this.moduleData" @showChart="showChart('value1')" /> -->
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="this.getModuleValue('IN_SOLLTEMP')" :value2="this.getModuleValue('CFG.luvAbstand')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Ist-Stellung ' + orientationStr1 + '/' + orientationStr2" :value1="this.getModuleValue('OUT_Stellung_1_Aktuell')" :value2="this.getModuleValue('OUT_Stellung_2_Aktuell')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Soll-Stellung ' + orientationStr1 + '/' + orientationStr2" :value1="this.getModuleValue('OUT_Stellung_1_Soll')" :value2="this.getModuleValue('OUT_Stellung_2_Soll')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_Differenz_1')" :value2="this.getModuleValue('OUT_Differenz_2')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="MIN/MAX" subtitle="Einstellung Begrenzungen und Stellungen" icon="fi fi-rr-settings-sliders" fgColor="yellow" bgColor="darkGreen"
                                @click="toggleOP($event, 'minmax')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Regler" subtitle="Einstellung Regler und Allgemein" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                                @click="toggleOP($event, 'controller')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Status Seite 1" :value="getModuleValue('OUT_StatusStr1').value" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Status Seite 2" :value="getModuleValue('OUT_StatusStr2').value" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('IN_RT').icon" :node="getModuleValue('OUT_Stellung_Max')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Stellung_1_Aktuell').icon" :node="getModuleValue('OUT_Stellung_1_Aktuell')" v-bind:show="showChartCurrentPosition1" @chart-close="showChartCurrentPosition1 = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Stellung_2_Aktuell').icon" :node="getModuleValue('OUT_Stellung_2_Aktuell')" v-bind:show="showChartCurrentPosition2" @chart-close="showChartCurrentPosition2 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniProgress from '@/components/fragments/widgetMiniProgress.vue';
import widgetMiniBinaryPulse from '@/components/fragments/widgetMiniBinaryPulse.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetVentilationController',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniProgress,
        widgetMiniBinaryPulse
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getSynoptoCode: 'types/getSynoptoCode',
            getVentsOrientation: 'types/getVentsOrientation',
            getVentsControlType: 'types/getVentsControlType',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    activeAlarms.push(this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`));
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        // rainCustom: function () {
        //     const value = this.getModuleValue('CFG.stellungRegenCustom').value;
        //     const label = this.getSynoptoCode.find(o => o.value === value);
        //     const channel = {
        //         label: label.label,
        //         value: value
        //     }
        //     console.log("DACHANNEL:", channel);
        //     return channel;
        // },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            showChartCurrentPosition1: false,
            showChartCurrentPosition2: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagMinMax: false,
            showDiagController: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Min/Max Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
                {
                    label: 'Regler Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'controller');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // vthis.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeData = [];
            this.nodeDataOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.*`, (err, response) => {
                if (err || !response) {
                    console.log(`[ENATWIDGET] error fetching params: ${err}`);
                }
                else {
                    if (Array.isArray(response) && response.length > 0) {
                        this.nodeData = JSON.parse(JSON.stringify(response));
                        this.nodeDataOld = JSON.parse(JSON.stringify(response));
                    }
                }
            }).then(() => {
                if (grp === 'minmax') {
                    this.showDiagMinMax = true;
                } else if (grp === 'controller') {
                    this.showDiagController = true;
                }
            });
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleController() {
            if (this.showDiagController) {
                this.showDiagController = false;
            } else {
                this.showDiagController = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;
                console.log(this.nodeData);
                console.log(this.nodeDataOld);

                for (let i=0; i<this.nodeData.length; i++) {
                    if (this.nodeDataOld[i].value !== this.nodeData[i].value) {
                        const entry = parseNode(this.nodeData[i], true);
                        saveArray.push(entry);
                    } else if ('plusminus' in this.nodeDataOld[i] && 'plusminus' in this.nodeData[i]) {
                        if (this.nodeDataOld[i].plusminus !== this.nodeData[i].plusminus) {
                            const entry = parseNode(this.nodeData[i], true);
                            saveArray.push(entry);
                        }
                    }
                }
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'minMax') this.showDiagMinMax = false;
                            if (diag === 'controller') this.showDiagController = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    if (diag === 'minMax') this.showDiagMinMax = false;
                    if (diag === 'controller') this.showDiagController = false;
                    this.hideOP();
                }
            }
        },
        saveController(event) {
            this.dialogLoading = true;
            let childElements = [];
            Object.entries(this.currentGrp.controller).forEach(([, value]) => {
                let returnObj = {};
                returnObj.displayName = value.key;
                returnObj.value = {
                    unit: value.unit,
                    factor: value.factor,
                    label: value.label,
                    key: value.key,
                    value: null
                }
                // check if parameter is a time parameter (H:M:S) style
                if (value.unit === '#HMS') {
                    returnObj.value.value = helpers.timeStringToInt(value.value);
                } else {
                    returnObj.value.value = parseInt(value.value * value.factor);
                }
                childElements.push(returnObj);
            });

            let childElements2 = [];
            // const oldVals2 = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            const oldVals2 = this.currentMap();
            console.log(">>> OLDVALS (MAP):", oldVals2);
            for (let [key, val] of this.node) {
                // console.log(">>> DA NODE EINTRAG:", key, val);
                if ((val.value !== oldVals2.get(key).value) && (val.type === "rw" || val.type === "write")) {
                    console.log(">>> CHANGE FOUND:", key, "OLDVAL:", oldVals2.get(key).value, "NEWVAL:", val.value, "VALTYPE:", val.type);
                    helpers.nodeScaler(val, key);
                    if (!isNaN(val.value)) {
                        childElements2.push(val);
                    } else {
                        if (val.unit === '#STRING') {
                            childElements2.push(val);
                        }
                    }
                }
            }

            let nodesToWrite = [];

            childElements2.forEach((element) => {
                // if (!isNaN(element.value.value)) {
                    const node = {
                        srv: this.nodeEntry.source.server,
                        // path: this.nodeEntry.source.path + '.' + element.displayName,
                        path: this.nodeEntry.source.path + '.' + element.key,
                        object: this.nodeEntry.source.path,
                        value: element,
                        datatype: 5,
                        arraytype: 0,
                        metadata: this.nodeEntry.metadata,
                        dataTypeIo: this.nodeEntry.value.datatype
                    }
                    nodesToWrite.push(node);
                // }
            });

            console.log(">>> NODES TO WRITE:", nodesToWrite);

            // if (abstand.value !== retObj.children[index].value) {
            if (nodesToWrite.length > 0) {
                // SocketioService.setServerData('writeOpc', 'value', abstand, (err, response) => {
                SocketioService.setServerData('writeOpc', 'value', nodesToWrite, (err, response) => {
                    console.log(response);
                    // this.getMenuList();
                    if (!err) {
                        if (response !== null) {
                            if (response === 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderung erfolgreich', detail: 'Wert wurde erfolgreich geändert', life: 1500 });
                            } else if (response > 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderungen erfolgreich', detail: response + ' Werte wurden erfolgreich geändert', life: 1500 });
                            } else {
                                this.$toast.add({ severity: 'info', summary: 'Änderung nicht notwendig', detail: 'Einstellwerte sind ident', life: 1500 });
                            }
                        }
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Fehler bei Änderung', detail: err + ' - ' + event, life: 1500 });
                    }
                });
            }


            // index = findIndexByDisplayName(retObj.children, 'LUV_Abstand');

            // if (abstandLuv.value !== retObj.children[index].value) {
            //     SocketioService.setServerData('writeOpc', 'value', abstandLuv, (err, response) => {
            //         console.log(response);
            //         // this.getMenuList();
            //         if (!err) {
            //             if (response !== null) {
            //                 // // const index = findIndexByDisplayName(retObj.children, currGrp.name);
            //                 // if (index !== -1) {
            //                 //     retObj.children[index].value = dataSwitch.value;
            //                 // }
            //                 // console.log(retObj);
            //                 // this.$store.dispatch('setValues', retObj);
            //                 this.$toast.add({ severity: 'success', summary: 'Ventil-Programm geändert', detail: event + response.value, life: 1000 });
            //             }
            //         } else {
            //             this.$toast.add({ severity: 'error', summary: 'Ventil-Programm nicht geändert', detail: event + this.currentGrp.enable, life: 1000 });
            //         }
            //     });
            // }
            this.dialogLoading = false;
            this.showDiagController = false;
            this.hideOP();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        else if (value === 'currentPosition1') this.showChartCurrentPosition1 = true;
        else if (value === 'currentPosition2') this.showChartCurrentPosition2 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>